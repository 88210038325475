import { Grid } from "@material-ui/core";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedIn from "@material-ui/icons/LinkedIn"; 
import LanguageIcon from '@material-ui/icons/Language';
//Component 
import { socialMedia } from "../../data/socialMedia";
//STYLES
import useStyles from "./style";

export default function Social({ color }){
  const classes = useStyles(); 
  const { instagram, facebook, twitter, linkedin, homepage } = socialMedia;
 
  return (
    <Grid item container spacing={2} justify="center">
      <Grid
        item
        component={"a"}
        target="_blank"
        rel="noreferrer noopener"
        href={homepage} >
        <LanguageIcon
          className={classes.snsIcon}
          color={color ? "primary" : "secondary"} />
      </Grid>
      <Grid
        item
        component={"a"}
        target="_blank"
        rel="noreferrer noopener"
        href={facebook} >
        <FacebookIcon
          className={classes.snsIcon}
          color={color ? "primary" : "secondary"}  />
      </Grid>
      <Grid
        item
        component={"a"}
        target="_blank"
        rel="noreferrer noopener"
        href={instagram} >
        <InstagramIcon
          className={classes.snsIcon}
          color={color ? "primary" : "secondary"} />
      </Grid>
      <Grid
        item
        component={"a"}
        target="_blank"
        rel="noreferrer noopener"
        href={twitter} >
        <TwitterIcon
          className={classes.snsIcon}
          color={color ? "primary" : "secondary"} />
      </Grid>
      <Grid
        item
        component={"a"}
        target="_blank"
        rel="noreferrer noopener"
        href={linkedin} >
        <LinkedIn
          className={classes.snsIcon}
          color={color ? "primary" : "secondary"} />
      </Grid> 
    </Grid>
  )
}; 