import Header from "../header";
import Footer from "../footer";
import Head from "next/head";

export default function Layout({ children, title, description, ogImage, url }){
  const pageUrl = "https://cidu.com.co"; 
  const ogImg = "/images/backNotification.jpg";
  // google site vertification
  //const GOOGLE_SITE_VERTIFICATION = process.env.GOOGLE_SITE_VERTIFICATION;
  return (
    <>
      <Head>
        <title>{title ? title : "Blog"}</title>
        <meta
          name="description"
          key="description"
          content={
            description
              ? description
              : "Consultoría de Ingeniería & Desarrollo Urabano S.A.S - Información confiable para decisiones efectivas."
          }
        />
        <meta
          property="og:title"
          content={title ? title : "Blog"}
          key="og:title"
        />
        <meta property="og:url" content={url ? url : pageUrl} key="og:url" />
        <meta
          property="og:image"
          content={ogImage ? ogImage : ogImg}
          key="og:image"
        />
        <meta
          property="og:description"
          content={
            description
              ? description
              : "Blog CIDU S.A.S - Tutoriales, Explicaciones sobre Avalúos Comerciales"
          }
          key="og:description"
        />
      </Head>
      <Header />
      <main>{children}</main>
      <Footer />
      <style jsx global>
        {`
          html,
          body {
            background: #e4e4e4;
            overflow-x: hidden;
            padding: 0 !important;
          }
          #__next {
            min-height: 100vh;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background: #e4e4e4;
          }
          main {
            flex: 1;
            background: #e4e4e4;
          }
        `}
      </style>
    </>
  );
}