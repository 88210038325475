import Link from "../../link";
import { useRouter } from "next/router";
import { Container, Grid, Typography } from "@material-ui/core";
import { routes } from "../../../data/routes";
import Social from "../../social";
//STYLES
import useStyles from "./style";

export default function Footer(){
  const classes = useStyles();
  const path = routes;
  const router = useRouter();
  
  return (
    <footer className={classes.footer}>
      <Container maxWidth="lg">
        <Grid container spacing={3} justify="center">
          {path.map(({ name, link }) => (
            <Grid item key={link}>
              <Link href={link}>
                <Typography
                  className={classes.link}
                  style={{
                    fontWeight: router.pathname === link && "bold",
                    borderBottom: router.pathname === link && "1px solid #86bbd8", }} >
                  {name}
                </Typography>
              </Link>
            </Grid>
          ))}
        </Grid>
        <Grid container direction="column" style={{ margin: "1.2em 0" }}>
          <Social />
        </Grid>
        <Grid
          item
          container 
          justify="center"
          style={{
            textDecoration: "none",
          }}
        >
          <Typography className={classes.copylight}>
            {'Copyright ©'}
            {' '}
            {new Date().getFullYear()}
            {' CIDU - Consultoría de Ingeniería & Desarrollo Urbano S.A.S - Todos los Derechos Reservados'}
          </Typography>
        </Grid>
      </Container>
    </footer>
  );
}